import { Button, Card, Col, Image, Popover, Row } from 'antd'
import { useLocation, useHistory } from 'react-router-dom'

import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import Datatable from '~/components/shared-components/Datatable'
import { useQueryState } from '~/common/hooks/useQueryState'
import TimeHelper from '~/helpers/time-helper'
import { ParameterItem } from './components/ParameterItem'
import { useItem } from '~/views/redux/hooks/useItem'
import { useEffect, useState } from 'react'
import {
  convertAddressToProperFormat,
  convertOasToUSD,
  formatAddress,
  formatItemName,
  formatNumber,
  formatTransaction,
} from '~/helpers/common'
import LoadingItemDetail from './components/LoadingItemDetail'
import {
  getBalanceItem,
  getNameItemV2,
  getNameItemV3,
} from '~/helpers/ItemName'
import configs from '~/configs'
import { useCommon } from '~/views/redux/hooks/useCommon'
import {
  checkTransactionConfirmation,
  fromWei,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { useAccount } from '~/common/hooks/useAccount'
import { useSDK } from '@metamask/sdk-react'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import SelectWallet from '~/components/Modals/SelectWallet'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import {
  getTypeItem,
  ifaceContractMarketplace,
  getOrderIdFromTxHash,
} from '~/blockchain/contract'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import SellToMKPModal from '~/components/Modals/SellToMKPModal'
import TransactionCompletedSell from '~/components/Modals/TransactionCompletedSell'
import { ROUTE } from '~/views/routes/config'
import { getbgQuality } from '~/helpers/quality'
import SellToShopModal from '~/components/Modals/SellToShopModal'
import { useShop } from '~/views/redux/hooks/useShop'
import { useAccountProfile } from '~/views/redux/hooks/useAccountProfile'
import { useTheme } from '~/common/theme/redux/hooks/useTheme'
import TransactionCompletedSellShop from '~/components/Modals/TransactionCompletedSellShop'
import getImageHash from '~/assets/data/ImageHash.json'

export const ItemsDetail = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const [txHash, setTxHash] = useState()
  const { user } = useAccount()
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [isModalSuccessSellOpen, setIsModalSuccessSellOpen] = useState(false)
  const [isModalSellOpen, setIsModalSellOpen] = useState(false)
  const [isModalOpenShop, setIsModalOpenShop] = useState(false)
  const [isModalSuccessShopOpen, setIsModalSuccessOpenShop] = useState(false)
  const { actions: actionsShop } = useShop()
  const { actions: actionsProfile } = useAccountProfile()
  const { actions: actionTheme } = useTheme()
  // eslint-disable-next-line
  const { t } = useTranslation()
  const location = useLocation()
  const history = useHistory()
  const { page, pageSize, setPage, setPageSize } = useQueryState()
  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  const {
    actions,
    data: { itemDetail, isLoading },
  } = useItem()
  const [dataHistories, setDataHistories] = useState([])
  const [totalData, setTotalData] = useState(0)

  const [maxBalance, setMaxBalance] = useState(0)
  // const [loadBalance, setLoadbalence] = useState(false)
  useEffect(() => {
    if (user && itemDetail) {
      // setLoadbalence(true)
      loadBalanceItem()
    }
  }, [itemDetail])
  const [checkBonusItem, setCheckBonusItem] = useState(false)
  useEffect(() => {
    if (itemDetail?.quality === 'B') {
      setCheckBonusItem(true)
    } else {
      setCheckBonusItem(false)
    }
  }, [itemDetail])
  const loadBalanceItem = async () => {
    const balance = await getBalanceItem(
      itemDetail?.addressContract,
      user.address_wallet,
      itemDetail?.nft_id,
    )
    setMaxBalance(Number(balance))
  }

  // useEffect(() => {
  //   setLoadbalence(false)
  // }, [maxBalance])

  const columns = [
    {
      title: t('monsterDetail.trading.event'),
      dataIndex: 'event',
      key: 'event',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.from'),
      dataIndex: 'from',
      key: 'from',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.to'),
      dataIndex: 'to',
      key: 'to',
      align: 'center',
      render: (data) => {
        let link = `${configs.NETWORK.scan}address/${data}`
        return (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link}
            style={{ color: '#40A9FF' }}
          >
            {convertAddressToProperFormat(formatAddress(data))}
          </a>
        )
      },
    },
    {
      title: t('monsterDetail.trading.price'),
      dataIndex: 'price',
      align: 'center',
      key: 'price',
      render: (data) => {
        return (
          <div>
            <Row gutter={4}>
              <Col span={12} style={{ display: 'flex', justifyContent: 'end' }}>
                {formatNumber(data)}
              </Col>
              <Col
                span={12}
                style={{ display: 'flex', justifyContent: 'start' }}
              >
                OAS
              </Col>
            </Row>
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.amount'),
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (data) => {
        return <div>{data}</div>
      },
    },
    {
      title: t('monsterDetail.trading.date'),
      dataIndex: 'created_at',
      key: 'date',
      align: 'center',
      render: (data) => {
        return (
          <div>
            {TimeHelper(Number(data) * 1000, {
              format: 'YYYY-MM-DD HH:mm:ss',
            })}
          </div>
        )
      },
    },
    {
      title: t('monsterDetail.trading.txHash'),
      dataIndex: 'txHash',
      key: 'txHash',
      align: 'center',
      render: (data) => {
        const link = `${configs.NETWORK.scan}tx/${data}`
        return (
          <a
            style={{ color: '#40A9FF' }}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {formatTransaction(data)}
          </a>
        )
      },
    },
  ]

  const handleSell = async (tokenID, price, quantity) => {
    try {
      // eslint-disable-next-line
      console.log({ tokenID, price, quantity })
      setLoadingModal(true)
      if (itemDetail?.quality === 'SHOP') {
        const data = {
          address: user?.address_wallet,
          itemId: tokenID,
          amount: Number(quantity),
        }
        actionsShop.sellItemShop(data, (res) => {
          if (res?.txid) {
            setLoadingModal(false)
            handleCancelShop()
            setTxHash(res?.txid)
            showModalSuccessShop()
            actionsProfile.getAccountProfile((res) => {
              actionTheme.updateInfoUser({
                ...res?.player_info,
              })
            })
          }
        })
      } else if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        const {
          contract,
          iface,
          address: addressContract,
        } = getTypeItem(itemDetail?.type)

        let allowance = await contract.isApprovedForAll(
          user.address_wallet,
          configs.ADDRESS_MARKETPLACE,
        )
        if (allowance) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let data = {
            from: providerOAS.accounts[0],
            data: iface.encodeFunctionData('setApprovalForAll', [
              configs.ADDRESS_MARKETPLACE,
              true,
            ]),
            to: addressContract,
            nonce: nonce,
            gasLimit: 21000,
          }

          const signedTransaction = await providerOAS.request({
            method: 'eth_signTransaction',
            params: [data],
          })

          const transaction = await ethersWeb3Provider.sendTransaction(
            String(signedTransaction),
          )
          checkTransactionConfirmation(transaction?.hash).then((r) => {
            if (r === 'confirmed') {
              // eslint-disable-next-line
              console.log('Approve:', r, transaction?.hash)
              sell(addressContract, tokenID, price, quantity)
            }
          })
        }
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        const {
          contract,
          iface,
          address: addressContract,
        } = getTypeItem(itemDetail?.type)

        let allowance = await contract.isApprovedForAll(
          user.address_wallet,
          configs.ADDRESS_MARKETPLACE,
        )

        if (allowance) {
          sell(addressContract, tokenID, price, quantity)
        } else {
          let nonce = await sdk.getProvider().request({
            method: 'eth_getTransactionCount',
            params: [user.address_wallet, 'latest'],
          })
          sdk
            .getProvider()
            .request({
              method: 'eth_sendTransaction',
              params: [
                {
                  from: user.address_wallet,
                  data: iface.encodeFunctionData('setApprovalForAll', [
                    configs.ADDRESS_MARKETPLACE,
                    true,
                  ]),
                  to: addressContract,
                  nonce,
                },
              ],
            })
            .then((txHash) => {
              checkTransactionConfirmation(txHash).then((r) => {
                if (r === 'confirmed') {
                  // eslint-disable-next-line
                  console.log('Approve:', r, txHash)
                  sell(addressContract, tokenID, price, quantity)
                }
              })
            })
            .catch((error) => {
              setLoadingModal(false)
              // eslint-disable-next-line
              console.error(error)
            })
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const sell = async (contractAddress, tokenID, price, quantity) => {
    try {
      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'createMarketItemSale',
            [contractAddress, tokenID, toWei(price), quantity],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then(async (r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            const order = await getOrderIdFromTxHash(transaction?.hash)
            history.push(
              `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}/${order}`,
            )
            await actions?.getDetailItem(
              {
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: order,
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancelModalSell()
            setTxHash(transaction?.hash)
            showModalSuccessSell()
          }
        })
      } else {
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'createMarketItemSale',
                  [contractAddress, tokenID, toWei(price), quantity],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then(async (r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                const order = await getOrderIdFromTxHash(txHash)
                history.push(
                  `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}/${order}`,
                )
                await actions?.getDetailItem(
                  {
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: order,
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancelModalSell()
                setTxHash(txHash)
                showModalSuccessSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
      setLoadingModal(false)
    }
  }

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            itemDetail?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(itemDetail?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}`,
            )
            actions?.getDetailItem(
              {
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: undefined,
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  itemDetail?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(itemDetail?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                history.push(
                  `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}`,
                )
                actions?.getDetailItem(
                  {
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: undefined,
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [itemDetail?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            history.push(
              `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                location.pathname.slice(1).split('/')[1]
              }/${location.pathname.slice(1).split('/')[2]}`,
            )
            actions?.getDetailItem(
              {
                _id: location.pathname.slice(1).split('/')[1],
                owner_address: location.pathname.slice(1).split('/')[2],
                orderId: undefined,
              },
              () => {},
              () => {
                history.push('/pageNotFound')
              },
            )
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [itemDetail?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                history.push(
                  `${ROUTE.ITEM_DETAIL.SUB_PATH}/${
                    location.pathname.slice(1).split('/')[1]
                  }/${location.pathname.slice(1).split('/')[2]}`,
                )
                actions?.getDetailItem(
                  {
                    _id: location.pathname.slice(1).split('/')[1],
                    owner_address: location.pathname.slice(1).split('/')[2],
                    orderId: undefined,
                  },
                  () => {},
                  () => {
                    history.push('/pageNotFound')
                  },
                )
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }
  const checkQuantity = location.pathname.slice(1).split('/')[6]

  useEffect(() => {
    actionsCommon.getTema()
  }, [txHash])

  useEffect(() => {
    const params = {
      _id: location.pathname.slice(1).split('/')[1],
      owner_address: location.pathname.slice(1).split('/')[2],
    }

    const orderId = location.pathname.slice(1).split('/')[3]
    if (orderId !== 'undefined') {
      params.orderId = orderId
    }
    const checkHash = location.pathname.slice(1).split('/')[5]
    const nft_id = location.pathname.slice(1).split('/')[4]
    if (checkHash === 'Regeneration_Hash') {
      params.nft_id = nft_id
    }
    if (checkHash === 'Regeneration Hash') {
      params.nft_id = nft_id
    }
    actions?.getDetailItem(
      params,
      () => {},
      () => {
        history.push('/pageNotFound')
      },
    )
  }, [])

  useEffect(() => {
    if (
      itemDetail.tokenId ||
      itemDetail.nft_id ||
      (itemDetail.tokenId || itemDetail.nft_id) == 0
    ) {
      actionsCommon.getHistoriesToken(
        {
          addressContract: itemDetail?.addressContract,
          tokenId: itemDetail.tokenId || itemDetail.nft_id,
          page: page,
        },
        async (res) => {
          let data = await Promise.all(
            res.data.map((temp, index) => {
              return {
                key: index,
                event:
                  temp?.type === 'OrderCreated'
                    ? 'For Sale'
                    : temp?.type === 'OrderSuccessful'
                    ? 'Buy'
                    : undefined,
                time: temp?.timestamp,
                from: temp?.from,
                to: temp?.to,
                price: fromWei(temp?.priceInWei),
                amount: temp.amount,
                txHash: temp?.transactionHash,
                created_at: temp?.timestamp,
              }
            }),
          )
          setTotalData(res?.total)
          setDataHistories(data)
        },
      )
    }
  }, [txHash, page, itemDetail])

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user && user !== null) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleShare = () => {
    setIsModalSuccessSellOpen(false)
  }

  const handleCancelSuccessSell = () => {
    setIsModalSuccessSellOpen(false)
  }

  const showModalSell = (box) => {
    if (box?.quality === 'SHOP') {
      setIsModalOpenShop(true)
    } else {
      setIsModalSellOpen(true)
    }
  }

  const showModalSuccessSell = () => {
    setIsModalSuccessSellOpen(true)
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const handleCancelModalSell = () => {
    setIsModalSellOpen(false)
  }

  const handleCancelShop = () => {
    setIsModalOpenShop(false)
  }
  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessSellOpen(false)
    setIsModalSuccessOpenCancelSell(false)
  }
  const showModalSuccessShop = () => {
    setIsModalSuccessOpenShop(true)
  }

  const handleCancelSuccessShop = () => {
    setIsModalSuccessOpenShop(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const ButtonItem = () => {
    if (itemDetail?.orderId) {
      if (
        itemDetail?.seller?.toLowerCase() ===
        user?.address_wallet?.toLowerCase()
      ) {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCancelSell()
            }}
          >
            Cancel
          </Button>
        )
      } else {
        return (
          <Button
            className="blockchain__button"
            onClick={() => {
              showModalCheckout()
            }}
          >
            Buy Now
          </Button>
        )
      }
    } else {
      return (
        <>
          {checkBonusItem ? (
            <Popover content={<>{t('messageCheckBonus')}</>}>
              <Button
                className=" btnCheckSell blockchain__button"
                disabled={checkBonusItem}
              >
                Sell
              </Button>
            </Popover>
          ) : (
            <Button
              className="blockchain__button"
              // loading={loadBalance}
              onClick={() => {
                showModalSell(itemDetail)
              }}
            >
              Sell
            </Button>
          )}
        </>
      )
    }
  }
  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyCommon>
      {isLoading && <LoadingItemDetail />}
      {!isLoading && (
        <div className="relative container__common mx-auto z-10 top-[84px]">
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-0 sm:gap-4 pt-5 sm:pt-12">
            <div className="flex flex-col mb-3 sm:mb-0">
              <div className="relative card__detail w-[93.2%] h-[300px] md:w-[93.2%] md:h-[656px] flex items-center justify-center">
                <div className="relative flex items-center justify-center h-full w-full">
                  <div className="relative flex flex-col w-[93%] h-[93%] justify-between">
                    <div
                      className="h-full flex flex-col items-center p-[10px] text-[18px] justify-center bg-cover bg-no-repeat"
                      style={{
                        background: getbgQuality(itemDetail?.quality),
                        clipPath:
                          'polygon(97% 100%, 100% 97%, 100% 3%, 97% 0%, 3% 0%, 0% 3%, 0% 97%, 3% 100%)',
                      }}
                    >
                      <div className="relative">
                        <Image
                          className="relative card__detail_img w-[80%] h-[80%] max-h-[350px] object-contain ease-in-out duration-300 z-50"
                          src={
                            itemDetail?.type === 'Regeneration_Hash'
                              ? itemDetail?.name === 'Hash Fragment' ||
                                itemDetail?.name ===
                                  'Regeneration Hash [Random]'
                                ? `${process.env.REACT_APP_BASE_URL_IMAGE}/public/image/image/hash/Regeneration_Hash_Random.png`
                                : `${
                                    process.env.REACT_APP_BASE_URL_IMAGE
                                  }/public/image/image/hash/${getItemGraphic(
                                    itemDetail?.name,
                                  )}`
                              : `${
                                  process.env.REACT_APP_BASE_URL_IMAGE
                                }/public/image/image/items/${getNameItemV2(
                                  itemDetail?.item_type,
                                )}.png`
                          }
                          alt=""
                          preview={false}
                          loading="lazy"
                        />
                        <div
                          className={`absolute ${
                            itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
                              ? 'left-[-2.75rem] bottom-[-40%]'
                              : itemDetail?.item_type ===
                                'REGENERATION_HASH_RANDOM_R'
                              ? 'left-[-0.75rem] bottom-[-40%]'
                              : 'left-1 bottom-[-20%]'
                          }`}
                        >
                          <div>{t('quality')}</div>
                        </div>
                        <div
                          className={`absolute ${
                            itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
                              ? 'right-[-2.75rem] bottom-[-40%]'
                              : itemDetail?.item_type ===
                                'REGENERATION_HASH_RANDOM_R'
                              ? 'right-[-0.75rem] bottom-[-40%]'
                              : 'right-1 bottom-[-20%]'
                          }`}
                        >
                          {checkQuantity}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <Card className="mt-3">
                <Row>
                  <Col span={12} className="text-[16px]">
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.ownerAddress')}
                    </div>
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.contractAddress')}
                    </div>
                    <div className="mb-3 h-[25px]">
                      {t('farmDetail.tokenID')}
                    </div>
                  </Col>
                  <Col span={12} className="text-end">
                    <div className="mb-3 h-[25px]">
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${
                          itemDetail?.orderId
                            ? itemDetail?.seller
                            : itemDetail?.owner_address
                        }`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {convertAddressToProperFormat(
                          formatAddress(
                            itemDetail?.orderId
                              ? itemDetail?.seller
                              : itemDetail?.owner_address,
                          ),
                        )}
                      </a>
                    </div>

                    <div className="mb-3 h-[25px]">
                      {/* {formatAddress(itemDetail?.addressContract)} */}
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}address/${itemDetail?.addressContract}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {formatAddress(itemDetail?.addressContract)}
                      </a>
                    </div>
                    <div className="mb-3 h-[25px]">
                      <a
                        style={{ color: '#40A9FF' }}
                        href={`${configs.NETWORK.scan}token/${itemDetail?.addressContract}/instance/${itemDetail?.nft_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {itemDetail?.id ?? itemDetail?.nft_id}
                      </a>
                    </div>
                  </Col>
                </Row>
              </Card>
            </div>
            <div className="col-span-2">
              <div className="flex items-center">
                <img
                  className="mr-2 w-[42px] h-[42px] object-contain	"
                  src={`${process.env.PUBLIC_URL}/svg/qualityTag/${itemDetail?.quality}.svg`}
                  alt=""
                />
                {itemDetail?.item_type === 'REGENERATION_HASH_OOO_R' ? (
                  <>
                    <div className="title__monster_detail">
                      {getNameItemV3(itemDetail?.item_type)?.name.replace(
                        '〇〇〇',
                        formatItemName(itemDetail.name),
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="title__monster_detail">
                      {getNameItemV3(itemDetail?.item_type)?.name}{' '}
                    </div>
                  </>
                )}
              </div>
              <Card className="mt-3 mb-8">
                {itemDetail?.price ? (
                  <div>
                    <Row>
                      <Col span={12} className="flex items-center">
                        {itemDetail?.price && (
                          <div className="relative z-10 flex items-center">
                            <img
                              className="mr-2"
                              src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                              alt=""
                              width={32}
                              height={32}
                            />
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(itemDetail?.price)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    itemDetail?.price,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          </div>
                        )}
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonItem />{' '}
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="mr-3">Floor Price</div>
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={22}
                            height={22}
                          />
                          <div className="flex items-center">
                            <div className="flex text-[14px] sm:text-[16px] font-[700]">
                              {formatNumber(itemDetail?.floorPrice)}
                            </div>
                            <span className="text-[10px] sm:text-[14px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  itemDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <>
                    <Row>Floor Price</Row>
                    <Row>
                      <Col span={12} className="flex items-center">
                        <div className="relative z-10 flex items-center">
                          <img
                            className="mr-2"
                            src={`${process.env.PUBLIC_URL}/imgs/coins/oasis.svg`}
                            alt=""
                            width={32}
                            height={32}
                          />
                          {/* <div className="flex items-center">
                            <div className="flex text-[20px] sm:text-[32px] font-[700]">
                              {formatNumber(itemDetail?.floorPrice)}
                            </div>
                            <span className="text-[16px] sm:text-[20px] flex">
                              <div className="px-2"> ~ </div> $
                              {formatNumber(
                                convertOasToUSD(
                                  itemDetail?.floorPrice,
                                  allTEMAs?.tema,
                                ),
                              )}
                            </span>
                          </div> */}
                          {itemDetail?.floorPrice ? (
                            <div className="flex items-center">
                              <div className="flex text-[20px] sm:text-[32px] font-[700]">
                                {formatNumber(itemDetail?.floorPrice)}
                              </div>
                              <span className="text-[16px] sm:text-[20px] flex">
                                <div className="px-2"> ~ </div> $
                                {formatNumber(
                                  convertOasToUSD(
                                    itemDetail?.floorPrice,
                                    allTEMAs?.tema,
                                  ),
                                )}
                              </span>
                            </div>
                          ) : (
                            <div className="text-[24px]">No Listing</div>
                          )}
                        </div>
                      </Col>
                      <Col span={12} className={`${'text-end'}`}>
                        <ButtonItem />
                      </Col>
                    </Row>
                  </>
                )}
              </Card>

              <ParameterItem t={t} itemDetail={itemDetail} />
            </div>
          </div>
          {itemDetail?.quality === 'SHOP' ? (
            <></>
          ) : (
            <div className="mt-5 mb-20">
              <Datatable
                scroll={{ x: 1300 }}
                breadcrumb={
                  <div className="text-[24px] mb-5">
                    {t('monsterDetail.tradingHistory')}
                  </div>
                }
                columns={columns}
                dataSource={dataHistories}
                loading={false}
                metadata={{
                  perPage: pageSize,
                  setPerPage: setPageSize,
                  page,
                  setPage,
                  total: totalData === 0 ? 1 : totalData,
                }}
                showPagination
              />
            </div>
          )}
        </div>
      )}
      <SellToMKPModal
        action={{
          isModalOpen: isModalSellOpen,
          handleOk: handleSell,
          handleCancel: handleCancelModalSell,
        }}
        tokenID={itemDetail?.nft_id}
        t={t}
        loadingModal={loadingModal}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        // tema={tema}
        item={itemDetail}
        balance={balanceOAS}
        quanlityTotal={maxBalance}
      />
      <SellToShopModal
        action={{
          isModalOpen: isModalOpenShop,
          handleOk: handleSell,
          handleCancel: handleCancelShop,
        }}
        tokenID={itemDetail?.nft_id}
        t={t}
        loadingModal={loadingModal}
        // name={getNameItemV3(itemDetail?.item_type)?.name}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        balance={balanceOAS}
        quanlityTotal={maxBalance}
      />
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={itemDetail}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        loadingModal={loadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedSell
        action={{
          isModalOpen: isModalSuccessSellOpen,
          handleOk: handleShare,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={getNameItemV3(itemDetail?.item_type)?.name}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />

      <TransactionCompletedSellShop
        action={{
          isModalOpen: isModalSuccessShopOpen,
          // handleOk: handleShare,
          handleCancel: handleCancelSuccessShop,
        }}
        t={t}
        // name={`${getNameItemV3(itemDetail?.item_type)?.name}`}
        name={
          itemDetail?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(itemDetail?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(itemDetail.name),
              )
            : getNameItemV3(itemDetail?.item_type)?.name
        }
        store={'Shop'}
        txHash={txHash}
      />
    </BodyCommon>
  )
}

export default ItemsDetail
