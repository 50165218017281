import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Input,
  Pagination,
  Row,
  Spin,
} from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from '~/common/hooks/useTranslation'
import BodyCommon from '~/components/shared-components/BodyCommon'
import {
  ITEM_SORT_MKP,
  ITEM_SORT_MKP_JA,
  SCREEN_SIZE,
} from '~/common/constants'
import { ArrowUpOutlined, FilterOutlined } from '@ant-design/icons'
import { FilterItems } from './ItemsDetail/components/FilterItems'
import { useItem } from '~/views/redux/hooks/useItem'
import { useQueryState } from '~/common/hooks/useQueryState'
import { NoData } from '~/components/shared-components/NoData'
import { ItemsCardMyAsset } from '~/components/shared-components/Card/ItemsCardMyAsset'
import { getNameItem, getNameItemV1, getNameItemV3 } from '~/helpers/ItemName'
import { useAccount } from '~/common/hooks/useAccount'
import {
  checkTransactionConfirmation,
  getBalanceOAS,
  hexToNumberString,
  numberToHex,
  toWei,
} from '~/blockchain/provider'
import { ethersWeb3Provider, providerOAS } from '~/blockchain/oas'
import { ifaceContractMarketplace } from '~/blockchain/contract'
import configs from '~/configs'
import { changeNetworkInMetamask } from '~/blockchain/metamask'
import CheckoutModal from '~/components/Modals/Checkout'
import CancelSell from '~/components/Modals/CancelSell'
import TransactionCompletedCancelSell from '~/components/Modals/TransactionCompletedCancelSell'
import TransactionCompletedMarketplace from '~/components/Modals/TransactionCompletedMarketplace'
import SelectWallet from '~/components/Modals/SelectWallet'
import { useCommon } from '~/views/redux/hooks/useCommon'
import { useSDK } from '@metamask/sdk-react'
import { useMediaQuery } from 'react-responsive'
import getImageHash from '~/assets/data/ImageHash.json'
import { formatItemName } from '~/helpers/common'
export const Items = () => {
  const { sdk } = useSDK()
  // eslint-disable-next-line
  const { t } = useTranslation()
  const [sort, setSort] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.sort ||
      ITEM_SORT_MKP[0],
  )
  const [typeSort, setTypeSort] = useState('-1')
  const { keyword, setKeyword } = useQueryState()
  const [form] = Form.useForm()
  const { user } = useAccount()
  const [isModalOpenBuy, setIsModalOpenBuy] = useState(false)
  const [isModalOpenCancel, setIsModalOpenCancel] = useState(false)
  const [balanceOAS, setBalanceOAS] = useState(0)
  const [nftActive, setNftActive] = useState(0)

  const [txHash, setTxHash] = useState()
  const [loadingModal, setLoadingModal] = useState(false)
  const [isModalSuccessOpenBuy, setIsModalSuccessOpenBuy] = useState(false)
  const [isModalSuccessOpenCancelSell, setIsModalSuccessOpenCancelSell] =
    useState(false)
  const [isModalConnectOpen, setIsModalConnectOpen] = useState(false)
  // const [category, setCategory] = useState(['Enhance_Item'])
  const [category, setCategory] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.category || [],
  )
  const [effect, setEffect] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.effect || [],
  )
  const [quality, setQuality] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.quality || [],
  )
  const [mainSeedFilterRegeneration, setMainSeedFilterRegeneration] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))
      ?.mainSeedFilterRegeneration || [],
  )
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.pageSize || 10,
  )
  const [page, setPage] = useState(
    JSON.parse(localStorage.getItem('ItemsMarketplace'))?.page || 1,
  )

  const {
    actions,
    data: { allItems, total, isLoading },
  } = useItem()

  const {
    actions: actionsCommon,
    data: { allTEMAs },
  } = useCommon()
  //Refresh data
  const refreshData = useCallback(() => {
    const params = {
      page,
      pageSize: pageSize,
      search: keyword,
      sort_by_field: sort.value,
      sort_by_dir: typeSort,
      category: category,
      effect: effect,
      quality: quality,
      main_seed: mainSeedFilterRegeneration,
    }
    actions.getAllItem(params)
  }, [
    page,
    pageSize,
    sort,
    typeSort,
    keyword,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])

  useEffect(() => {
    refreshData()
  }, [
    txHash,
    page,
    pageSize,
    keyword,
    sort,
    typeSort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])

  const handleChangeSort = (e) => {
    setSort(ITEM_SORT_MKP[e.key])
  }

  const showModalConnect = () => {
    setIsModalConnectOpen(true)
  }

  const handleCancelConnect = () => {
    setIsModalConnectOpen(false)
  }

  const showModalCheckout = () => {
    if (!user) {
      showModalConnect()
      return
    }
    setIsModalOpenBuy(true)
  }

  const showModalCancelSell = () => {
    setIsModalOpenCancel(true)
  }

  const handleCancelBuy = () => {
    setIsModalOpenBuy(false)
  }

  const handleCancel = () => {
    setIsModalOpenCancel(false)
  }

  const showModalSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(true)
  }

  const handleShareCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const handleCancelSuccessCancelSell = () => {
    setIsModalSuccessOpenCancelSell(false)
  }

  const showModalSuccessBuy = () => {
    setIsModalSuccessOpenBuy(true)
  }

  const handleShareBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const handleCancelSuccessBuy = () => {
    setIsModalSuccessOpenBuy(false)
  }

  const loadBalanceOAS = () => {
    getBalanceOAS(user.address_wallet).then((res) => {
      setBalanceOAS(res)
    })
  }

  useEffect(() => {
    if (user) {
      loadBalanceOAS()
    }
  }, [user, txHash])

  const handleBuy = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
            nftActive?.orderId,
          ]),
          to: configs.ADDRESS_MARKETPLACE,
          value: numberToHex(toWei(nftActive?.price)),
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancelBuy()
            setTxHash(transaction?.hash)
            showModalSuccessBuy()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData('buyItem', [
                  nftActive?.orderId,
                ]),
                to: configs.ADDRESS_MARKETPLACE,
                value: numberToHex(toWei(nftActive?.price)),
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancelBuy()
                setTxHash(txHash)
                showModalSuccessBuy()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleCancelSell = async () => {
    try {
      setLoadingModal(true)

      if (localStorage.getItem('wallet') === 'OAS') {
        const nonce = await ethersWeb3Provider
          .getSigner(providerOAS.accounts[0])
          .getTransactionCount()

        let data = {
          from: providerOAS.accounts[0],
          data: ifaceContractMarketplace.encodeFunctionData(
            'cancelMarketItemSale',
            [nftActive?.orderId],
          ),
          to: configs.ADDRESS_MARKETPLACE,
          nonce: nonce,
          gasLimit: 21000,
        }

        const signedTransaction = await providerOAS.request({
          method: 'eth_signTransaction',
          params: [data],
        })

        const transaction = await ethersWeb3Provider.sendTransaction(
          String(signedTransaction),
        )
        checkTransactionConfirmation(transaction?.hash).then((r) => {
          if (r === 'confirmed') {
            // eslint-disable-next-line
            console.log(r, transaction?.hash)
            setLoadingModal(false)
            handleCancel()
            setTxHash(transaction?.hash)
            showModalSuccessCancelSell()
          }
        })
      } else {
        await sdk.connect()
        await changeNetworkInMetamask(
          sdk.getProvider(),
          configs.NETWORK.chainId,
          setLoadingModal,
        )
        await sdk.connect()
        let networkId = await sdk
          .getProvider()
          .request({ method: 'eth_chainId', params: [] })
        networkId = hexToNumberString(networkId)

        if (parseInt(networkId) !== parseInt(configs.NETWORK.chainId)) return
        let nonce = await sdk.getProvider().request({
          method: 'eth_getTransactionCount',
          params: [user.address_wallet, 'latest'],
        })
        sdk
          .getProvider()
          .request({
            method: 'eth_sendTransaction',
            params: [
              {
                from: user.address_wallet,
                data: ifaceContractMarketplace.encodeFunctionData(
                  'cancelMarketItemSale',
                  [nftActive?.orderId],
                ),
                to: configs.ADDRESS_MARKETPLACE,
                nonce,
              },
            ],
          })
          .then((txHash) => {
            checkTransactionConfirmation(txHash).then((r) => {
              if (r === 'confirmed') {
                // eslint-disable-next-line
                console.log(r, txHash)
                setLoadingModal(false)
                handleCancel()
                setTxHash(txHash)
                showModalSuccessCancelSell()
              }
            })
          })
          .catch((error) => {
            setLoadingModal(false)
            // eslint-disable-next-line
            console.error(error)
          })
      }
    } catch (error) {
      // console.error(error)
      // eslint-disable-next-line
      setLoadingModal(false)
    }
  }

  const handleChangeTypeSort = () => {
    if (typeSort === '-1') {
      setTypeSort('1')
    } else if (typeSort === '1') {
      setTypeSort('-1')
    }
  }

  const handleSubmit = (values) => {
    setKeyword(values?.search ? values?.search?.trim() : '')
  }

  const onChangePage = (page) => {
    setPage(page)
  }
  const onChangePageSize = (current, size) => {
    setPageSize(size)
  }

  useEffect(() => {
    actionsCommon.getTema()
  }, [])
  const isMobile = useMediaQuery({ query: SCREEN_SIZE.TABLET })
  const [openFilterItem, setOpenFilterItem] = useState(false)
  const showDrawer = () => {
    setOpenFilterItem(true)
  }

  const onClose = () => {
    setOpenFilterItem(false)
  }
  const clearAll = () => {
    // setCategory(['Enhance_Item'])
    setCategory([])
    setEffect([])
    setQuality([])
  }
  useEffect(() => {
    if (page > 1) {
      onChangePage(1)
    }
  }, [
    txHash,
    pageSize,
    sort,
    typeSort,
    category,
    effect,
    quality,
    mainSeedFilterRegeneration,
  ])
  const pageFilter = JSON.parse(localStorage.getItem('ItemsMarketplace'))?.page
  useEffect(() => {
    if (pageFilter > 1) {
      setPage(pageFilter)
    }
  }, [pageFilter])

  function getItemGraphic(itemId) {
    // Kiểm tra điều kiện đặc biệt trước khi xử lý
    if (itemId === 'UPA 1912') {
      itemId = 'UPA_1912'
    }
    if (itemId === 'UPA Gorem') {
      itemId = 'UPA_Gorem'
    }
    if (itemId === 'UPA AC') {
      itemId = 'UPA_AC'
    }
    if (itemId === 'UPA ODEN') {
      itemId = 'UPA_ODEN'
    }
    if (itemId === 'UPA Ossan') {
      itemId = 'UPA_Ossan'
    }
    if (itemId === 'UPA Villain') {
      itemId = 'UPA_Villain'
    }
    if (itemId === 'Yamato T Shirt') {
      itemId = 'YamatoT_Shirt'
    }
    if (itemId === 'Sound Horse') {
      itemId = 'Soundhorse'
    }

    // Loại bỏ khoảng trắng
    let formattedItemId = itemId?.replace(/\s+/g, '')

    for (let i = 0; i < getImageHash.length; i++) {
      let formattedImageHashItemId = getImageHash[i].itemId.replace(/\s+/g, '')

      if (formattedImageHashItemId === formattedItemId) {
        return getImageHash[i].itemGraphic
      }
    }
    return null
  }
  return (
    <BodyCommon>
      <Row className={`${isMobile ? '' : 'container__common mx-auto h-full'}`}>
        <Col xl={6} md={6} sm={24} xs={24} className="relative">
          {isMobile ? (
            <></>
          ) : (
            <>
              {' '}
              <div className="flex w-full h-full">
                <FilterItems
                  sort={sort}
                  page={page}
                  pageSize={pageSize}
                  category={category}
                  effect={effect}
                  quality={quality}
                  mainSeedFilterRegeneration={mainSeedFilterRegeneration}
                  setCategory={setCategory}
                  setEffect={setEffect}
                  setQuality={setQuality}
                  setMainSeedFilterRegeneration={setMainSeedFilterRegeneration}
                />
              </div>
            </>
          )}
        </Col>
        <Col xl={18} md={18} sm={24} xs={24} className="relative">
          <div className="z-10 pl-0 sm:pl-[15px] relative sm:sticky top-0 mt-0 sm:mt-[104px] pb-12">
            <div className="pt-0" style={{ marginTop: '100px' }}>
              {/* Search */}
              <div className="flex mt-5 flex-col">
                <Row>
                  <Col xl={16} md={16} sm={24} xs={24}>
                    <Form
                      layout="vertical"
                      onFinish={handleSubmit}
                      form={form}
                      translate="yes"
                      autoComplete="off"
                      size="middle"
                      validateTrigger={['onBlur', 'onChange']}
                    >
                      <Row className="flex justify-between">
                        <Col span={24}>
                          <div
                            className={`${
                              isMobile
                                ? 'text-[24px] ml-[11px] font-normal'
                                : 'text-[24px] font-normal'
                            }`}
                            style={{ color: '#FFFFFF' }}
                          >
                            {t('countItem', {
                              total: total,
                            })}
                            {total > 1 ? t('more') : ''}
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </Col>
                  <Col xl={8} md={8} sm={24} xs={24}>
                    {isMobile ? (
                      <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                        <Dropdown
                          className="flex items-center cursor-pointer ml-3  sort__value"
                          menu={{
                            items:
                              user?.player_language === 'en'
                                ? ITEM_SORT_MKP
                                : ITEM_SORT_MKP_JA,
                            selectable: true,
                            onClick: handleChangeSort,
                          }}
                          placement="bottomRight"
                          trigger={['click']}
                        >
                          <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                            <div className="title">
                              {t(`common.filter.sort.${sort?.valueLanguage}`)}
                            </div>
                          </Button>
                        </Dropdown>

                        <Button
                          className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                          onClick={() => {
                            handleChangeTypeSort()
                          }}
                        >
                          <ArrowUpOutlined
                            style={{ fontSize: 18 }}
                            rotate={typeSort === '1' ? 0 : 180}
                          />
                        </Button>
                        <div>
                          <Button
                            type="primary"
                            onClick={showDrawer}
                            className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[100px]"
                          >
                            <FilterOutlined />
                            {t('filter')}
                          </Button>
                          <Drawer
                            title={t('filter')}
                            width={330}
                            placement="left"
                            onClose={onClose}
                            open={openFilterItem}
                            extra={
                              <div
                                className="text-[16px] text-[#FAAD14] cursor-pointer"
                                onClick={() => {
                                  clearAll()
                                }}
                              >
                                {t('clearAll')}
                              </div>
                            }
                          >
                            <div className="flex w-full h-full">
                              <FilterItems
                                sort={sort}
                                page={page}
                                pageSize={pageSize}
                                category={category}
                                effect={effect}
                                quality={quality}
                                mainSeedFilterRegeneration={
                                  mainSeedFilterRegeneration
                                }
                                setCategory={setCategory}
                                setEffect={setEffect}
                                setQuality={setQuality}
                                setMainSeedFilterRegeneration={
                                  setMainSeedFilterRegeneration
                                }
                                classCustom="filter__profile"
                              />
                            </div>
                          </Drawer>
                        </div>
                      </div>
                    ) : (
                      <div className="flex items-center text-[16px] gap-2 justify-normal sm:justify-end">
                        <div> {t('sortBy')}</div>

                        <Dropdown
                          className="flex items-center cursor-pointer ml-3  sort__value"
                          menu={{
                            items:
                              user?.player_language === 'en'
                                ? ITEM_SORT_MKP
                                : ITEM_SORT_MKP_JA,
                            selectable: true,
                            onClick: handleChangeSort,
                          }}
                          placement="bottomRight"
                          trigger={['click']}
                        >
                          <Button className="flex bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[200px]">
                            <div className="title">
                              {' '}
                              {t(`common.filter.sort.${sort?.valueLanguage}`)}
                            </div>
                          </Button>
                        </Dropdown>

                        <Button
                          className="flex justify-center items-center bg-[#0d2758] px-[10px] py-[16px] h-[44px] w-[44px]"
                          onClick={() => {
                            handleChangeTypeSort()
                          }}
                        >
                          <ArrowUpOutlined
                            style={{ fontSize: 18 }}
                            rotate={typeSort === '1' ? 0 : 180}
                          />
                        </Button>
                      </div>
                    )}
                  </Col>
                </Row>
              </div>

              {/* Item */}
              <Spin className="min-h-[350px]" spinning={isLoading}>
                <div className="flex flex-wrap justify-center gap-4 mt-5">
                  {allItems?.map((data, i) => {
                    let type_id
                    switch (data?.type) {
                      case 'Traning_Item':
                        type_id = 0
                        break
                      case 'Enhance_Item':
                        type_id = 1
                        break
                      case 'Fusion_Item':
                        type_id = 2
                        break
                      case 'Regeneration_Item':
                        type_id = 3
                        break
                      default:
                        type_id = 0
                        break
                    }
                    // eslint-disable-next-line
                    data.type_id = type_id
                    // eslint-disable-next-line
                    // data.name = getNameItemV3(data?.item_type)?.name

                    return (
                      <ItemsCardMyAsset
                        t={t}
                        key={i?.toString()}
                        data={data}
                        tema={allTEMAs?.tema}
                        button={{
                          title:
                            data?.seller?.toLowerCase() ===
                            user?.address_wallet?.toLowerCase()
                              ? 'Cancel'
                              : 'Buy',
                          functionButton: () => {
                            setNftActive(data)
                            if (
                              data?.seller?.toLowerCase() ===
                              user?.address_wallet?.toLowerCase()
                            ) {
                              showModalCancelSell()
                            } else {
                              showModalCheckout()
                            }
                          },
                        }}
                        image={
                          data?.type === 'Regeneration_Hash'
                            ? getNameItem(3, data?.nft_id)?.image
                              ? data?.item_type === 'REGENERATION_HASH_RANDOM_R'
                                ? `${
                                    process.env.REACT_APP_BASE_URL_IMAGE
                                  }/public/image/image/hash/${
                                    getNameItem(3, data?.nft_id)?.image
                                  }.png`
                                : `${
                                    process.env.REACT_APP_BASE_URL_IMAGE
                                  }/public/image/image/items/${
                                    getNameItem(3, data?.nft_id)?.image
                                  }.png`
                              : `${
                                  process.env.REACT_APP_BASE_URL_IMAGE
                                }/public/image/image/hash/${getItemGraphic(
                                  data?.name,
                                )}`
                            : `${
                                process.env.REACT_APP_BASE_URL_IMAGE
                              }/public/image/image/items/${
                                getNameItemV1(data?.type_id, data?.nft_id)
                                  ?.image
                              }.png`
                        }
                      />
                    )
                  })}
                </div>
              </Spin>

              {!isLoading && total === 0 && <NoData />}

              {total > 0 && (
                <div className="pagination__common">
                  <Pagination
                    onChange={onChangePage}
                    onShowSizeChange={onChangePageSize}
                    className="mt-5"
                    size="small"
                    locale={{ jump_to: 'Go to', page: '' }}
                    total={total}
                    // defaultCurrent={page}
                    current={page}
                    pageSize={pageSize}
                    showSizeChanger
                    showQuickJumper
                    showTitle
                  />
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <CheckoutModal
        action={{
          isModalOpen: isModalOpenBuy,
          handleOk: handleBuy,
          handleCancel: handleCancelBuy,
        }}
        t={t}
        box={nftActive}
        loadingModal={loadingModal}
        tema={allTEMAs?.tema}
        balance={balanceOAS}
      />
      <CancelSell
        action={{
          isModalOpen: isModalOpenCancel,
          handleOk: handleCancelSell,
          handleCancel: handleCancel,
        }}
        t={t}
        name={
          nftActive?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(nftActive?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(nftActive.name),
              )
            : getNameItemV3(nftActive?.item_type)?.name
        }
        loadingModal={loadingModal}
        // name={'Monster'}
        // tema={tema}
      />
      <TransactionCompletedCancelSell
        action={{
          isModalOpen: isModalSuccessOpenCancelSell,
          handleOk: handleShareCancelSell,
          handleCancel: handleCancelSuccessCancelSell,
        }}
        t={t}
        // name={`${nftActive?.name}`}
        name={
          nftActive?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(nftActive?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(nftActive.name),
              )
            : getNameItemV3(nftActive?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <TransactionCompletedMarketplace
        action={{
          isModalOpen: isModalSuccessOpenBuy,
          handleOk: handleShareBuy,
          handleCancel: handleCancelSuccessBuy,
        }}
        t={t}
        // name={`${nftActive?.name}`}
        name={
          nftActive?.item_type === 'REGENERATION_HASH_OOO_R'
            ? getNameItemV3(nftActive?.item_type)?.name.replace(
                '〇〇〇',
                formatItemName(nftActive.name),
              )
            : getNameItemV3(nftActive?.item_type)?.name
        }
        store={'Marketplace'}
        txHash={txHash}
      />
      <SelectWallet
        action={{
          isModalOpen: isModalConnectOpen,
          handleCancel: handleCancelConnect,
        }}
        t={t}
      />
    </BodyCommon>
  )
}

export default Items
